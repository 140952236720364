import React from "react"

import Layout from "../components/UI/Layout/layout"
import SEO from "../components/seo"
import SpaceTop from "../components/UI/SpaceTop"
import { Container, Col, Row } from "../components/UI/Grid"
import SectionTitle from "../components/UI/SectionTitle"
import TypedFont from "../components/TypedFont"
import BlockQuote from "../components/UI/Quote"
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import SVG from 'react-inlinesvg'

import icon_reactjs from '../assets/images/icons/svg/react.svg'
import icon_angularjs from '../assets/images/icons/svg/angular.svg'
import icon_nextjs from '../assets/images/icons/svg/nextjs.svg'
import icon_gatsbyjs from '../assets/images/icons/svg/gatsby.svg'
import icon_strapi from '../assets/images/icons/svg/strapi.svg'
import Contact from "../components/Contact"
import CustomTable from "../components/UI/Table"

import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import GaLink from "../components/UI/Ga-link"

const WebDevelopmentPage = () => {


  const data = useStaticQuery(graphql`
  query {
    mearn:file(relativePath: {eq: "web/mearn.png"}) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`)

  return (

    <Layout >
      <SEO
        metaTitle="Web Entwicklung"
        metaDescription="Web Entwicklung für jede Anforderung. Dein Transparenter Partner für performante Seiten mit React, Angular oder JAMStack mit Gatsby JS"
        metaKeywords="web, angular, react, spa, ssr, jamstack, gatsby, strapi, frontend, static site generator, webseite"
      />
      <SpaceTop />
      <Container>

        <SectionTitle className="section-title" leftAlign={true} UniWidth="100%">
          <TypedFont tag="h1">Web Entwicklung - Toolstack</TypedFont>
          <BlockQuote><TypedFont delay>Lieber weniger anbieten, dafür besser ausführen.</TypedFont></BlockQuote>
        </SectionTitle>

        <Fade>
          <h2>
            Getreu diesem Motto haben wir uns im Kern auf <GaLink to="blog?tag=react" style={{ color: '#53c1de' }}>React</GaLink> & <GaLink to="blog?tag=angular" style={{ color: '#e23237' }}>Angular</GaLink> spezialisiert, womit wir
            jede Anforderung im Web abdecken können, von einfachen stark SEO geprägten Präsentationsseiten bis komplexen Server/Client Applikationen mit stark frequentiertem Datenfluss.
          </h2>
        </Fade>

        <fade>
          <CustomTable>
            <thead>
              <tr>
                <th></th>
                <th>
                  <GaLink to="blog?tag=react" category="backlink" site="web-entwicklung" value="blog/tag/react" style={{ color: '#53c1de' }}>
                    React
                  </GaLink>
                </th>
                <th>
                  <GaLink to="blog?tag=angular" category="backlink" site="web-entwicklung" value="blog/tag/angular" style={{ color: '#e23237' }}>
                    Angular
                  </GaLink>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Developers</td>
                <td>Facebook</td>
                <td>Google</td>
              </tr>
              <tr>
                <td>Release</td>
                <td>2013</td>
                <td>2010</td>
              </tr>
              <tr>
                <td>npm - Weekly Downloads (05.2020)</td>
                <td>7.733k</td>
                <td>434k</td>
              </tr>
              <tr>
                <td>GitHub Stars (05.2020)</td>
                <td>147k</td>
                <td>59.9k</td>
              </tr>
              <tr>
                <td>Rendering</td>
                <td>Server Side</td>
                <td>Client Side</td>
              </tr>
              <tr>
                <td>Model</td>
                <td>Virtual DOM</td>
                <td>MVC</td>
              </tr>
            </tbody>
          </CustomTable>
        </fade>

        <Fade>
          <h2>Welche Technologien beherrschen wir?</h2>

          <BlockQuote>
            Die Frage ist nicht <u>Was</u> besser ist, die Frage ist <u>Wann</u> was besser ist.
          </BlockQuote>
        </Fade>

        <Fade>
          <CustomTable>
            <thead>
              <tr>
                <th>Technologie</th>
                <th>
                  <GaLink to="blog?tag=react" category="backlink" site="web-entwicklung" value="blog/tag/react" style={{ color: '#53c1de' }}>
                    React
                  </GaLink>
                </th>
                <th>
                  <GaLink to="blog?tag=angular" category="backlink" site="web-entwicklung" value="blog/tag/angular" style={{ color: '#e23237' }}>
                    Angular
                  </GaLink>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>

                <td>
                  <GaLink to="blog/category/frontend" category="backlink" site="web-entwicklung" value="blog/category/frontend">
                    Single Page Application ( SPA )
                  </GaLink>
                </td>

                <td>
                  <GaLink to="blog?tag=react" category="backlink" site="web-entwicklung" value="blog/tag/react">
                    <SVG width="50px" height="50px" src={icon_reactjs}></SVG>
                  </GaLink>
                </td>
                <td>
                  <GaLink to="blog?tag=angular" category="backlink" site="web-entwicklung" value="blog/tag/angular">
                    <SVG width="50px" height="50px" src={icon_angularjs}></SVG>
                  </GaLink>
                </td>
              </tr>
              <tr>
                <td>
                  <GaLink to="blog/category/backend" category="backlink" site="web-entwicklung" value="blog/category/backend">
                    Server Side Rendering ( SSR )
                  </GaLink>
                </td>
                <td>
                  <GaLink to="blog?tag=nextjs" category="backlink" site="web-entwicklung" value="blog/tag/nextjs">
                    <SVG width="100px" height="50px" src={icon_nextjs}></SVG>
                  </GaLink>
                </td>
                <td>-</td>
              </tr>
              <tr>
                <td>
                  <GaLink to="blog/category/jamstack" category="backlink" site="web-entwicklung" value="blog/category/jamstack">JAMStack</GaLink>
                </td>
                <td>
                  <GaLink to="blog?tag=gatsbyjs" category="backlink" site="web-entwicklung" value="blog/tag/gatsbyjs">
                    <SVG width="50px" height="50px" src={icon_gatsbyjs}></SVG>
                  </GaLink>
                  <GaLink to="blog?tag=strapi" category="backlink" site="web-entwicklung" value="blog/tag/strapi">
                    <SVG width="50px" height="50px" src={icon_strapi}></SVG>
                  </GaLink>
                </td>
                <td>-</td>
              </tr>
              <tr>
                <td>
                  <GaLink to="blog/category/backend" category="backlink" site="web-entwicklung" value="blog/category/backend">Server / Client Stacks</GaLink>
                </td>
                <td colSpan="2">
                  <Img alt="icon_mearn" style={{ width: '300px' }} fluid={data.mearn.childImageSharp.fluid}></Img>
                </td>

              </tr>
            </tbody>
          </CustomTable>
        </Fade>

        <Fade>
          <h2 style={{ marginBottom: '50px' }}>
            Für mehr Informationen zu den einzelnen Technologien darfst du gerne auf meinem <GaLink style={{color:'#FB7B81'}} to="blog" category="backlink" site="web-entwicklung" value="blog">Blog</GaLink> vorbeischauen.
          </h2>
        </Fade>

        <Fade>
          <h2>Du weißt nicht welche Technologie am besten zu deinem Projekt, deinen Anforderungen passt? Sprich mich einfach an.</h2>
        </Fade>

        <Fade>
          <Contact page='web'></Contact>
        </Fade>

        {/* <h2>Beide Frameworks gehören der SPA ( Single Page Applikation ) Technologie an und erfreuen sich hoher Beliebtheit.</h2>
      <h2>SPAs sind vor allem dann geeignet wenn mehr Wert auf Performance als auf SEO gelegt wird, zB.: für Konfiguratoren oder Microsites. </h2>
      <br/>
      <h1>Für Präsentationsseiten empfehle ich für gewöhnlich <span style={{color:'#744c9e'}}>Gatsby JS</span></h1>
      <br/>
      <h2>Was ist Gatsby und warum nicht lieber WordPress?</h2> */}
      </Container>
    </Layout >
  );
}

export default WebDevelopmentPage
