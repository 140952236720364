import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const ScrollspyMenu = ({ menuItems, setExpand, ...props }) => {

  const trackEvent = (menu) => {
    trackCustomEvent({
      category: "link",
      action: "click",
      label: "navigation",
      value: menu.name.toLowerCase()
    });
    setExpand(false);

  }

  const addAllClasses = [""];
  if (props.className) {
    addAllClasses.push(props.className);
  }
  return (
    <ul className={addAllClasses.join(" ")}>
      {menuItems.map((menu, index) => (
        <li
          onClick={() => trackEvent(menu)}
          key={index}
          className={
            menu.subItems !== undefined ? "nav-item has-dropdown" : "nav-item"
          }
        >
          <AnchorLink
            to={menu.path}
          >
            {menu.name}
          </AnchorLink>
        </li>
      ))}
    </ul>
  );
};

export default ScrollspyMenu;
