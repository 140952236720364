const Data = {
    menuItems: [
      {
        name: "Home",
        path: "/",
      },
      {
        name: "App",
        path: "/app-entwicklung",
        offset: "-50"
      },
      {
        name: "Web",
        path: "/web-entwicklung",
        offset: "-50"
      },
      {
        name: "Blog",
        path: "/blog",
      },
      // {
      //   name: "Portfolio",
      //   path: "/portfolio",
      //   offset: "-50"
      // },
      {
        name: "CV",
        path: "/cv",
        offset: "-50"
      },
      {
        name: "Kontakt",
        path: "/#contact",
        offset: "-50"
      }
    ]
  };
  export default Data;
  