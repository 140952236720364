import React from 'react'
import TableStyleWrapper from './table.style'

const CustomTable = (props) => {
    return (
        
            <TableStyleWrapper>
                <table>
                    {props.children}
                </table>
            </TableStyleWrapper>

    )
}

export default CustomTable
