/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"


const getSchemaOrgJSONLD = ({
  isBlogPost,
  url,
  title,
  image,
  description,
  publishedDate,
  modifiedDate,
  logo
}) => {
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
    },

  ];

  return isBlogPost
    ? [
      ...schemaOrgJSONLD,
      {
        '@context': 'https://philipp-rhoenisch.de',
        '@type': 'BlogPosting',
        url,
        name: title,
        alternateName: title,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image,
        },
        description,
        author: {
          '@type': 'Person',
          name: 'Philipp Rhönisch',
        },
        publisher: {
          '@type': 'Organization',
          url: 'https://www.philipp-rhoenisch.de',
          logo: logo,
          name: 'Philipp Rhönisch',
        },
        mainEntityOfPage: {
          '@type': 'WebSite',
          '@id': url,
        },
        datePublished: publishedDate,
        dateModified: modifiedDate
      },
    ]
    : schemaOrgJSONLD;
};

function SEO({
  metaDescription,
  metaImageUrl,
  metaImageSize,
  metaKeywords,
  metaTitle,
  metaPublishedDate,
  metaModifiedDate,
  lang,
  type,
  meta,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            keywords
            logo
            twitterUserName
          }
        }
      }
    `
  )
  const isBlogPost = (type === 'article');
  const publishedDate = metaPublishedDate || '01.01.2020';
  const modifiedDate = metaModifiedDate || '01.01.2020';

  const { pathname } = useLocation()
  const description = metaDescription || site.siteMetadata.description
  const image = metaImageUrl ? `${site.siteMetadata.siteUrl}${metaImageUrl}` : site.siteMetadata.logo;
  const url = `${site.siteMetadata.siteUrl}${pathname}`;
  const keywords = metaKeywords || site.siteMetadata.keywords
  const imageSizes = metaImageSize || { height: 0, width: 0 }
  const logo = site.siteMetadata.logo

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    isBlogPost,
    url,
    metaTitle,
    image,
    metaDescription,
    publishedDate,
    modifiedDate,
    logo
  });

  const imgSizes = [
    {
      property: `og:image:height`,
      content: imageSizes.height,
    },
    {
      property: `og:image:width`,
      content: imageSizes.width,
    },
  ]

  if(isBlogPost){
    meta.concat(imgSizes)
  }

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: "keywords",
          content: keywords
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:image:secure_url`,
          content: image,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:type`,
          content: type || 'website',
        },
        {
          name: `twitter:card`,
          content: 'summary'
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:url`,
          content: url,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.twitterUserName,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitterUserName,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `article:author`,
          content: site.siteMetadata.author,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  metaDescription: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
  metaSiteUrl: PropTypes.string,
  metaImageUrl: PropTypes.string,
  metaImageSize: PropTypes.object,
  metaTitle: PropTypes.string,
  metaKeywords: PropTypes.string,
  metaPublishedDate: PropTypes.string,
  metaModifiedDate: PropTypes.string,
  twitterUserName: PropTypes.string
}

export default SEO
