import styled from 'styled-components';

const FooterStyleWrapper = styled.footer`
    margin-top:50px;
    background: ${props => props.theme.secondaryLightColor};
    padding:50px 0;

    p,span,h1,a{
        color: ${props => props.theme.headingColor}        
    }
    h2{
        margin-bottom:10px;
        font-size:18px;
    }

    ul{
        margin:0;
        li{
            list-style:none;
            padding:0;
            margin:0;            
        }
    }
`
export default FooterStyleWrapper