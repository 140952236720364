import React from 'react';
import styled, { css } from 'styled-components'


const TitleWrap = styled.div`
margin-left: auto;
margin-right: auto;
width: 40%;
text-align: center;

${props => (props.rightAlign && css(
    {
        "text-align": 'right'
    }
))}
${props => (props.leftAlign && css(
    {
        "text-align": 'left'
    }
))}
${props => (props.UniWidth && css(
    {
        "width": props.UniWidth
    }
))}

h1{
    font-weight: 300;
    font-size: 60px;
    line-height: 75px;
    margin: 0 0 38px 0; 
    @media(max-width:662px){
        font-size:48px;
        line-height:60px;
    }
    span{
        font-weight: 600;
    }
}
h4{
    margin-bottom: 8px;
    font-size: 18px;
    color: ${props => props.theme.secondaryColor};
    text-transform: uppercase;
    font-weight:500;
}
h2{
    font-size: 40px;
    font-weight: 300;
    line-height: 50px;
    color: ${props => props.theme.headingColor ? props.theme.headingColor : '#2C0075'};
    margin: 0 0 75px 0;
    span,a{
        font-weight: 600;
    }
}
@media only screen and (max-width: 912px) {
    width: 100%;
    h2{
        font-size: 24px;
        line-height: 35px;
    }
    .section-title h2{
        margin-bottom: 20px;
    }
}
`
const SectionTitle = ({ children, ...props }) => {

    return (
        <TitleWrap {...props}>
            {children}
        </TitleWrap>
    );
};

export default SectionTitle;
