import { Link } from "gatsby";
import SVG from 'react-inlinesvg'
import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { Container } from "../UI/Grid/index";
import NavigationWrap from "./navigation.style";
import Data from "./utility/data.js";
import ScrollspyMenu from "./utility/ScrollspyMenu";
import logo from '../../assets/images/logo/logo.svg'
import GaLink from "../UI/Ga-link";


const Navigation = () => {
  const [expand, setExpand] = useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () =>
      window.pageYOffset > 50 ? setScroll(true) : setScroll(false)
    );
  }, []);

  const f_setExpand = (shouldSetExpand) => {
    setExpand(shouldSetExpand);
  }

  return (
    <NavigationWrap className={`nav-block ${scroll ? "scrolled" : ""}`}>
      <Container>
        <div className="navbar-wrap">
          <GaLink to="/" site='navigation' value='home' category='logo' className="logo">
            <SVG src={logo}></SVG>
          </GaLink>
          <nav className="nav">
            <FaBars
              className="mobile-menu-icon"
              onClick={() => f_setExpand(!expand)}
            />
            <ScrollspyMenu
              className={`collapsed ${expand ? "is-expanded" : ""}`}
              menuItems={Data.menuItems}
              setExpand={f_setExpand}
            />
          </nav>
        </div>
      </Container>
    </NavigationWrap>
  );
};

export default Navigation;
