import React from 'react'
import posed from 'react-pose';
import SplitText from 'react-pose-text';

const TypedFont = (props) => {

    const Sidebar = posed.div({
        exit: {
            x: props.delay ? '-100%' : '-5%'
        },
        enter: {
            x: '0%',
            beforeChildren: true,
            staggerChildren: 50
        }
    });
    
    const charPoses = {
        exit: { opacity: 0 },
        enter: { opacity: 1 }
    };



    const test = props.tag === 'h1' ? 
        (<h1><SplitText charPoses={charPoses}>{props.children}</SplitText></h1>) :
        (<h2><SplitText charPoses={charPoses}>{props.children}</SplitText></h2>)

    return (
        <Sidebar className="sidebar" initialPose="exit" pose="enter">
            {test}
        </Sidebar>
    )
}

export default TypedFont
