import React from 'react';
import styled, { css } from 'styled-components'


const SpaceTopComponent = styled.div`
    padding-top: 190px;
    margin-top:80px;
    @media(max-width:662px){
        padding-top:100px;
    }
`
const SpaceTop = () => {

    return (
        <SpaceTopComponent></SpaceTopComponent>
    );
};

export default SpaceTop;
