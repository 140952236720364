import styled from 'styled-components';

const TableStyleWrapper = styled.div`
overFlow-x: scroll;
/* &::-webkit-scrollbar-thumb{
	border-radius: 8px;
	border: 3px solid #fff;
	background-color: rgba(0, 0, 0, .3);
}
&::-webkit-scrollbar{
	-webkit-appearance: none;
	width: 14px;
	height: 14px;
} */
table{
    margin: 40px 0;
    overflow-x:auto;
    thead{
        tr{
            th{
                :not(:first-child){
                    text-align:center;
                }
            }
        }
    }
    tbody{
        tr{
            td{
                padding:10px 10px;
                border: 1px dotted #ccc;
                :not(:first-child){
                    text-align:center;
                }
                .gatsby-image-wrapper{
                    margin: 0 auto;
                }
            }
        }
    }
}
`;

export default TableStyleWrapper