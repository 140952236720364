import React from 'react'
import QuoteStyleWrapper from './quote.style'

const BlockQuote = (props) => {
    return (
        <QuoteStyleWrapper>{props.children}</QuoteStyleWrapper>
    )
}

export default BlockQuote
