import React, { useState } from "react";

import { Container } from "../UI/Grid";
import SectionTitle from "../UI/SectionTitle";
import Button from "../UI/Button";
import ContactWrapper from "./contact.style";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { toast, Flip } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import { trackCustomEvent } from "gatsby-plugin-google-analytics";


const Contact = ({ page, header }) => {
  toast.configure()

  const toastConfig = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 3000,
    hideProgressBar: true,
    transition: Flip
  }

  if (!page)
    page = 'home';

  const pageItems = {
    'web': [
      { checked: true, color: 'secondary', label: "Allgemein", value: "Allgemein" },
      { checked: false, color: 'secondary', label: "React Pure", value: "React" },
      { checked: false, color: 'secondary', label: "Angular Pure", value: "Angular" },
      { checked: false, color: 'secondary', label: "NextJS", value: "NextJS" },
      { checked: false, color: 'secondary', label: "MEAN Stack", value: "MEAN" },
      { checked: false, color: 'secondary', label: "MERN Stack", value: "MERN" },
      { checked: false, color: 'secondary', label: "GatsbyJS", value: "GatsbyJS" },
      { checked: false, color: 'secondary', label: "Beratung", value: "Beratung" },
    ],
    'home': [
      { checked: true, color: 'secondary', label: "Allgemein", value: "Allgemein" },
      { checked: false, color: 'secondary', label: "App Entwicklung", value: "App Entwicklung" },
      { checked: false, color: 'secondary', label: "Web Entwicklung", value: "Web Entwicklung" },
      { checked: false, color: 'secondary', label: "Beratung", value: "Beratung" },
    ],
    'app': [
      { checked: true, color: 'secondary', label: "Allgemein", value: "Allgemein" },
      { checked: false, color: 'secondary', label: "Ionic", value: "Ionic" },
      { checked: false, color: 'secondary', label: "React Native", value: "React Native" },
      { checked: false, color: 'secondary', label: "Beratung", value: "Beratung" },
    ]
  }

  const [items, setItems] = React.useState([...pageItems[page]]);

  const handleChange = (event) => {
    const newItems = [...items];
    newItems.map(cb => {
      if (cb.value === event.target.name) {
        cb.checked = !cb.checked;
      }
      return cb;
    })
    setItems([...newItems]);
  };

  const getAllCheckedValuesAsString = () => {
    let checkedItems = items.filter(cb => {
      return cb.checked === true;
    })
    let itemsOnlyCheckedValue = checkedItems.map(cb => {
      return cb.value
    })
    return itemsOnlyCheckedValue.join(', ')
  }

  const sendForm = (ev) => {

    trackCustomEvent({ category: "button", action: "click", label: page, value: 'contact' });

    ev.preventDefault();
    const data = {
      from: ev.target.email.value,
      text: ev.target.text.value,
      topic: `${page}: ${getAllCheckedValuesAsString()}`
    }
    axios({
      method: 'post',
      url: `https://philipp-rhoenisch.herokuapp.com/email/send`,
      data: data
    }).then(() => {

      toast.success("Erfolgreich versendet!", toastConfig);
      setItems([...pageItems[page]]);
      document.getElementById("form").reset()

      trackCustomEvent({ category: "formular", action: "senden", label: page, value: 'erfolgreich' });

    }).catch(() => {
      toast.error("Irgendein Fehler ist unterlaufen !", toastConfig);
      trackCustomEvent({ category: "formular", action: "senden", label: page, value: 'fehler' });
    })

  }

  return (
    <ContactWrapper id="contact">

      <Container>
        <SectionTitle className="section-title" UniWidth="90%">
          <h4>Kontakt</h4>
        </SectionTitle>

        <SectionTitle className="section-title" UniWidth="90%">
          <h2>
            {header ? <span>{header}</span> : <span>Frag mich einfach</span>}
          </h2>
        </SectionTitle>
      </Container>
      <Container>
        <form id="form" onSubmit={(ev) => sendForm(ev)} name="contactform" method="post" action="#">
          <span className="head">Anliegen</span>
          <FormGroup row>

            {items.map((item, key) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox checked={item.checked} name={item.value} onChange={handleChange} />
                }
                label={item.label}
                color="secondary"
              />
            ))}

          </FormGroup>
          <input required name="email" type="email" placeholder="E-Mail" />
          <textarea required name="text" id="text" placeholder="Hallo"></textarea>
          <Button>Senden</Button>
        </form>
      </Container>
    </ContactWrapper>
  );
};

export default Contact;
