import Icon from '../../../assets/images/icons/quotes.svg';
import styled from 'styled-components';

const QuoteStyleWrapper = styled.blockquote`
    padding-left: 90px;
    padding-bottom: 30px;
    background: transparent;
    overflow: hidden;
    font-size: 32px;
    line-height: 45px;
    border-radius: 6px;
    margin: 60px 0 40px 0;
    position: relative;
    box-sizing: border-box;
    position: relative;
    color: ${props => props.theme.black ? props.theme.black : '#333333'};
    &:before{
        content: '';
        width: 60px;
        height: 3px;
        background: ${props => props.theme.black ? props.theme.black : '#333333'};
        position: absolute;
        top: 20px;
        left: 0;
    }
    &:after{
        content: url(${Icon});
        position: absolute;
        right: 0;
        bottom: -22px;
        transform: rotate(180deg);
        z-index: -1;
        opacity: 1;

        transition: all 1s ease-in-out;
    }

    span {
        display: block;
        font-size: 85%;
        margin-top: 10px;
        opacity: .8;
    }
    
`;

export default QuoteStyleWrapper;

