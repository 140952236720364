import React from 'react'
import { Link } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';


const GaLink = ({ to, site, value, category, className, extern, children, style }) => {

    const trackEvent = () => {
        trackCustomEvent({
            category: category || 'button',
            action: "click",
            label: site,
            value: value
        });
    }

    let Lin = <Link style={style} className={className} onClick={() => trackEvent()} to={to}>{children}</Link>;    
    if (extern) {
        Lin = <a style={style} className={className} onClick={() => trackEvent()} target="_blank" href={to} rel="noreferrer noopener"> {children}</a >        
    }

    return Lin;
}

export default GaLink
