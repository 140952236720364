import React from 'react'
import { SocialFriendsWrapper, SocialFriendWrapper, InfoWrapper } from './socialfriends.style'

import { Row, Col } from '../UI/Grid'
import { FaLinkedinIn, FaXing } from 'react-icons/fa'
import { AiOutlineMail, AiOutlineMedium } from 'react-icons/ai'
import { FiPhoneCall } from 'react-icons/fi'
import GaLink from '../UI/Ga-link'

const SocialFriends = ({ about }) => {

    const { email, telefon } = about;
    const Socials = [
        // {
        //     icon: <AiOutlineMedium size={'2rem'} />,
        //     link: 'mailto:info@philipp-rhoenisch.de',
        //     name: 'medium'
        // },
        {
            icon: <FaXing size={'2rem'} />,
            link: 'https://www.xing.com/profile/Phillipp_Rhoenisch/cv',
            name: 'xing'
        },
        {
            icon: <FaLinkedinIn size={'2rem'} />,
            link: 'https://www.linkedin.com/in/philipprhoenisch/',
            name: 'linkedin'
        }
    ]

    return (
        <SocialFriendsWrapper>
            <InfoWrapper>
                <li>
                    <AiOutlineMail size={'1rem'}></AiOutlineMail>
                    <a href="mailto:info@philipp-rhoenisch.de">
                        {email}
                    </a>
                </li>
                <li>
                    <FiPhoneCall size={'1rem'}></FiPhoneCall>
                    <span>
                        {telefon}
                    </span>
                </li>
            </InfoWrapper>
            <SocialFriendWrapper>
                {Socials.map((social, key) => (
                    <li className="socials" key={key}>

                        <GaLink category='link' site='footer' value={social.name} extern={true} to={social.link}>
                            {social.icon}
                        </GaLink>

                    </li>
                ))}
            </SocialFriendWrapper>

        </SocialFriendsWrapper>
    )
}

export default SocialFriends;
