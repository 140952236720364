import styled from 'styled-components';

const SocialFriendsWrapper = styled.section`
 
    svg{
        color:${props => props.theme.redColor};
    }
`;

const InfoWrapper = styled.ul`
    margin-bottom:20px!important;
    a,span{
        padding-left:10px;        
    }
    
`;

const SocialFriendWrapper = styled.ul`
    margin-top:20px;
    li{
        display:inline-block;
        
        a{
            margin-right:16px;
            display:inline-block;
            overflow:hidden;
            padding: 10px 10px 0px 10px;
            border-radius:10px;
            border:1px solid ${props => props.theme.redColor};
            transition:color .5s ease, background .5s ease;

            &:hover{
                background: ${props => props.theme.redColor};
                svg{
                    color: #fff;
                }
            }
        }
    }
`;


export { SocialFriendsWrapper, SocialFriendWrapper, InfoWrapper };