import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types'
import FooterStyleWrapper from './footer.style'
import { Container, Row, Col } from '../Grid'
import SocialFriends from '../../Social-friends'
import { Link } from 'gatsby';
import GaLink from '../Ga-link';

const Footer = props => {

    const data = useStaticQuery(
        graphql`
        query {
            about: strapiAbout {
                name    
                city
                country
                email
                telefon
                street
                post_code
            }
        }
    `)

    const { about } = data;
    const { city, country, street, post_code } = about;


    return (
        <FooterStyleWrapper>
            <Container>
                <Row>
                    <Col lg={4} sm={12} md={4}>
                        <h2>Kontakt</h2>
                        <SocialFriends about={about} />
                    </Col>
                    <Col lg={4} sm={12} md={4}>
                        <h2>Addresse</h2>
                        <p>
                            {street}<br />{post_code}, {city}<br /> {country}
                        </p>
                    </Col>
                    <Col lg={4} sm={12} md={4}>
                        <h2>Terms</h2>
                        <ul>
                            <li>
                                <GaLink category="footer" value="datenschutz" site="home" to='/datenschutz'>Datenschutz</GaLink>
                            </li>
                            <li>
                                <GaLink category="footer" value="impressum" site="home" to='/impressum'>Impressum</GaLink>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </FooterStyleWrapper>
    )
}

Footer.propTypes = {

}

export default Footer
