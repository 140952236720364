import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"
import { ThemeProvider } from "styled-components"
import theme from '../../../components/UI/Themes/Home.js'
import Navigation from "../../Navigation/index"
import { GlobalStyle } from "./global.style"
import "./layout.css"
import Footer from "../Footer/index.js"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

const Layout = (props) => {  
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <html lang="de" />
        <title>{`App Entwickler Philipp Rhönisch`}</title>
        <link rel="shortcut icon" type="image/x-icon" href="" />
      </Helmet>
      <GlobalStyle />
      {!props.modal && <Navigation />}
      {props.children}
      {!props.modal && <Footer />}
      <CookieConsent
        location="bottom"
        buttonText="OK!"
        style={{ background: "#8A57DE"}}
        acceptOnScroll={false}
        acceptOnScrollPercentage={95}
        cookieName="phillCookie"
        buttonStyle={{background: '#FFEBEC', borderRadius:'10px',color:'#FB7B81'}}
        expires={150}
        debug={false}
      >
        Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer <Link style={{color:'#fff',textDecoration: 'underline'}} to="datenschutz">Datenschutzerklärung</Link>.
      </CookieConsent>
    </ThemeProvider>

  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  modal: PropTypes.boolean
}

export default Layout;
